import Box from "@mui/material/Box";
import { css } from "@emotion/css";
import { isPast, isToday } from "date-fns";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Button, Card, CardContent, CardMedia } from "@mui/material";
import Carousel from "react-material-ui-carousel";

import { config } from "../env/config";
import { EventDetails } from "./EventDetails";
import { defaultThought } from "../content/content";
import { commonCarouselNavStyle, getUserKey, postData } from "../common/utils";
import image_audio from "../assets/site/image_audio.png";
import image_thoughts from "../assets/site/image_thoughts.png";
import image_instagram from "../assets/site/image_instagram.png";
import { useNavigate } from "react-router";

function DashboardContent() {
    const userData = getUserKey();
const navigate = useNavigate();

    const payload = {
        user_id: userData.id,
        member_id: userData.member_id,
        country_id: userData.country_id,
    };

    const [eventList, setEventList] = useState([]);
    const [homePageDetails, setHomePageDetails] = useState(defaultThought);
    const [videoDetails, setvideoDetails] = useState([]);
    const [subscribedEventList, setSubscribedEventList] = useState([]);
    const [eventData, setEventData] = useState([]);

    useEffect(() => {
        const videos = homePageDetails?.homeYoutube?.reduce(
            (aggr, curr, index) => {
                if (index % 2 === 0) {
                    aggr.push([curr]);
                } else {
                    aggr[aggr.length - 1].push(curr);
                }
                return aggr;
            },
            []
        );
        setvideoDetails(videos);
    }, [homePageDetails]);

    useEffect(() => {
        setEventData([...subscribedEventList, ...eventList]);
    }, [eventList, subscribedEventList]);

    useEffect(() => {
        fetchEventData();
        fetchHomePageData();
        fetchSubscribedEventData();
    }, []);

    const refreshEvents = () => {
        fetchEventData();
        fetchSubscribedEventData();
    };

    async function fetchEventData() {
        const data = await postData({
            url: `${config.serverUrl}/getEvent.php`,
            payload,
            navigate,
            highlightError: true,
        });
        if (data) {
            setEventList(data);
        }
    }
    async function fetchSubscribedEventData() {
        const data = await postData({
            url: `${config.serverUrl}/getOnlineEventList.php`,
            payload,
            getFullResponse: true,
        });
        if (data?.response) {
            const modifiedEvents = data.response.map((event) => ({
                isSubscribed: true,
                eventMsg: data.msg,
                ...event,
            }));
            setSubscribedEventList(modifiedEvents);
        }
    }

    async function fetchHomePageData() {
        const data = await postData({
            url: `${config.serverUrl}/getHomePageDetails.php`,
            payload: {
                country_id: userData.country_id,
            },
        });

        if (data) {
            setHomePageDetails(data);
        }
    }

    return (
        <Box
            component="main"
            sx={{
                display: "flex",
                flexDirection: "column",
                marginBottom: { xs: 2, md: 6 },
            }}
        >
            <Typography
                fontSize={{ xs: "16px", md: "20px" }}
                fontFamily="Open Sans, Semibold"
                margin="auto"
                mb={1}
                mt={1}
                color="#49255d"
            >
                {`Welcome ${userData.name} (${userData.member_id})`}
            </Typography>

            <Carousel
                navButtonsAlwaysVisible
                navButtonsProps={{
                    className: commonCarouselNavStyle,
                }}
            >
                {homePageDetails.homeBanner.length > 0 ? (
                    homePageDetails?.homeBanner.map((banner) => (
                        <Box
                            key={banner.id}
                            component="img"
                            sx={{
                                width: "100%",
                                height: { xs: "150px", md: "500px" },
                                padding: "8px 10px",
                                borderRadius: "4px",
                            }}
                            alt="Dashboard - Landing Photo"
                            src={`${config.imgUrl}${banner.imgPath}`}
                        />
                    ))
                ) : (
                    <></>
                )}
            </Carousel>

            <Container maxWidth="lg" sx={{ mt: 3, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                fontFamily: "Montserrat, SemiBold",
                                textAlign: "center",
                                fontSize: { xs: "18px", md: "24px" },
                                fontWeight: 700,
                            }}
                            component="h3"
                            variant="h3"
                            color="#000000"
                            mb={1}
                        >
                            Upcoming Courses
                        </Typography>
                        <Box
                            sx={{
                                width: "128px",
                                height: "7px",
                                background: "#f5f0e3",
                                margin: "auto",
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sx={{ marginBottom: { xs: 0, md: "30px" } }}
                    >
                        {eventData?.length === 0 ? (
                            <Typography fontSize="14px" mb={2} color="#898989">
                                No upcoming events scheduled.
                            </Typography>
                        ) : (
                            eventData?.map((event) => (
                                <EventDetails
                                    key={event.id}
                                    event={event}
                                    refreshEvents={refreshEvents}
                                />
                            ))
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <Thoughts homePageDetails={homePageDetails} />
                    </Grid>

                    <Grid item xs={12} mt={{ xs: 1, md: 7 }}>
                        <Box
                            sx={{
                                display: "grid",
                                gridTemplateColumns: {
                                    xs: "none",
                                    md: "1fr 2fr",
                                },
                                gridColumnGap: "70px",
                                width: "100%",
                            }}
                        >
                            {homePageDetails?.homeRelaxPlayList.length > 0 && (
                                <Box>
                                    <Typography
                                        fontSize={{ xs: "18px", md: "24px" }}
                                        fontFamily="Open Sans, Semibold"
                                        mb={2}
                                        color="#000000"
                                    >
                                        Harmonising Tunes
                                    </Typography>
                                    <Carousel
                                        navButtonsAlwaysVisible
                                        navButtonsProps={{
                                            className: commonCarouselNavStyle,
                                        }}
                                        className={css({
                                            padding: 0,
                                        })}
                                    >
                                        {homePageDetails?.homeRelaxPlayList?.map(
                                            (audio, index) => (
                                                <AudioBox
                                                    audio={audio}
                                                    key={index}
                                                />
                                            )
                                        )}
                                    </Carousel>
                                </Box>
                            )}

                            {videoDetails.length > 0 && (
                                <Box>
                                    <Typography
                                        fontSize={{ xs: "18px", md: "24px" }}
                                        fontFamily="Open Sans, Semibold"
                                        mb={2}
                                        mt={{ xs: "18px", md: "0px" }}
                                        color="#000000"
                                    >
                                        Shreekalp Videos
                                    </Typography>
                                    <Carousel
                                        navButtonsAlwaysVisible
                                        navButtonsProps={{
                                            className: commonCarouselNavStyle,
                                        }}
                                    >
                                        {videoDetails?.map((videoSet) => (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:
                                                        "space-between",
                                                    columnGap: "8px",
                                                }}
                                            >
                                                {videoSet?.map(
                                                    (video, index) => (
                                                        <VideoBox
                                                            video={video}
                                                            key={index}
                                                        />
                                                    )
                                                )}
                                            </Box>
                                        ))}
                                    </Carousel>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default function Dashboard() {
    return <DashboardContent />;
}

const VideoBox = ({ video }) => {
    return (
        <Box width={{ xs: "50%", md: "300px" }} height="170px">
            <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${video.url}`}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
            <Typography fontSize="14px" mt={2} color="#000000">
                {video.shortDesc}
            </Typography>
        </Box>
    );
};

const AudioBox = ({ audio }) => {
    return (
        <Box
            style={{ padding: 0 }}
            sx={{
                background: "#F8F9FF",
                height: "170px",
                borderRadius: "7px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box>
                <Box
                    sx={{
                        direction: "row",
                        marginBottom: "20px",
                        display: "flex",
                        columnGap: "8px",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            width: "46px",
                            height: "46px",
                            background: "#FFFFFF",
                            borderRadius: "6px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Box
                            component="img"
                            alt="Audio Tunes"
                            width="16px"
                            height="20px"
                            src={image_audio}
                        />
                    </Box>
                    <Typography
                        fontSize="18px"
                        fontFamily="Open Sans, Semibold"
                        color="#000000"
                    >
                        {/* {audio.title} */}
                        Coming Soon
                    </Typography>
                </Box>

                {/* <Button
          variant="contained"
          color="success"
          sx={{
            background: "#FA4245",
            height: "40px",
            width: "230px",
            color: "#FFF",
          }}
          target="_blank"
          href="https://www.instagram.com/divine_harmony_with_shreekalp/"
        >
          SUBSCRIBE TO LISTEN SONG
        </Button> */}
            </Box>
        </Box>
    );
};

export const DetailsBlock = ({ text, subText }) => (
    <>
        {text && subText && (
            <Grid item xs={12} md={6}>
                <Paper
                    sx={{
                        p: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Typography
                        component="p"
                        variant="p"
                        color="primary"
                        style={{ fontFamiy: "caudex", fontSize: "14px" }}
                    >
                        {text}
                    </Typography>
                    <Typography
                        component="p"
                        variant="p"
                        color="#7f7980"
                        style={{ fontFamiy: "caudex", fontSize: "14px" }}
                    >
                        {subText}
                    </Typography>
                </Paper>
            </Grid>
        )}
    </>
);

const Thoughts = ({ homePageDetails }) => {
    return (
        <Card
            sx={{
                width: "100%",
                boxShadow: "none",
                border: "1px solid #E6E6E6",
                borderRadius: "24px",
                padding: { xs: "16px 20px 0px 20px", md: "30px 30px 6px 30px" },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <CardMedia
                    component="img"
                    sx={{ width: 70, height: 40, borderRadius: 2 }}
                    image={image_thoughts}
                />
                <Typography
                    component="div"
                    variant="h5"
                    sx={{
                        fontSize: { xs: "16px", md: "24px" },
                        marginTop: "8px",
                    }}
                    fontFamily="Open Sans, Semibold"
                >
                    Shreekalp Thought
                </Typography>
                <Box
                    sx={{
                        width: "80px",
                        height: "4px",
                        background: "#f5f0e3",
                        margin: "auto",
                        marginBottom: "16px",
                    }}
                />
            </Box>
            <CardContent
                sx={{
                    width: "100%",
                    padding: 0,
                }}
            >
                <Carousel
                    navButtonsAlwaysVisible
                    navButtonsProps={{
                        className: commonCarouselNavStyle,
                    }}
                    Carouselanimaiton="slide"
                >
                    {homePageDetails?.homeQuotes?.map(
                        ({ id, title, shortDesc }) => (
                            <Box
                                key={id}
                                sx={{ marginBottom: { xs: "8px", md: 0 } }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    color="#000"
                                    fontSize={{ xs: "16px", md: "24px" }}
                                    fontFamily="Open Sans, Semibold"
                                    component="div"
                                    marginBottom="12px"
                                    fontWeight="500"
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="#000"
                                    fontSize={{ xs: "14px", md: "24px" }}
                                    fontFamily="Open Sans, Regular"
                                    component="div"
                                >
                                    {shortDesc}
                                </Typography>
                            </Box>
                        )
                    )}
                </Carousel>
                <Box
                    mt={1}
                    sx={{
                        textAlign: { xs: "center", md: "right" },
                    }}
                >
                    <Button
                        variant="text"
                        size="small"
                        sx={{
                            height: "40px",
                        }}
                        target="_blank"
                        href="https://www.instagram.com/divine_harmony_with_shreekalp/"
                    >
                        <Box
                            component="img"
                            alt="Instagram"
                            width="32px"
                            height="32px"
                            src={image_instagram}
                            mr={1}
                        />
                        divine_harmony_with_shreekalp
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};
